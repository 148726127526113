<template>
  <v-container>

    <h2>Kluringar</h2>
    <h5>Alla svar rättas efter rallyt och kan ge er tidsavdrag</h5>

    <v-container
    >
      <div v-if="fetchingResults" class="center">
          <vue-spinner />
      </div>
      <v-row
        v-else
        no-gutters
        style="height: 100px;"
      >
        <v-col
          cols="6"
          sm="3"
          v-for="n in numTrickyQuestions"
          :key="n"
        >
          <v-sheet v-if="answeredQuestions.includes(n)" @click="openSubmitDialog(n)" class="pa-6 ma-3 primary rounded white--text text-h6">
            {{ n }}
          </v-sheet>
          <v-sheet v-else @click="openSubmitDialog(n)" class="pa-6 ma-3 grey rounded white--text text-h6">
            {{ n }}
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4">
            <h2 class="primary--text pb-2">Svara på kluring #{{ dialogNumber }}</h2>
            <v-form ref="form" :disabled="trickyLoading" lazy-validation>
              <v-textarea
                prepend-icon="mdi-checkbox-marked-outline"
                name="answer"
                v-model="answer"
                label="Svar"
                placeholder="Rätt svar"
                type="text"
                required
                
              ></v-textarea>
            </v-form>
            <div v-if="trickyLoading" class="center">
              <vue-spinner />
            <p>Skickar in kluring...</p>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              color="primary"
              @click="submitTrickyQuestionAnswer"
              :disabled="trickyLoading"
            >
              Skicka svar
            </v-btn>
            <v-btn
              text
              @click="dialog = false"
            >
              Stäng
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </v-container>


</template>

<script>
import { mapActions, mapGetters } from "vuex"
import Spinner from "vue-simple-spinner"
import {notify} from '../../utils/notification'

export default {
  name: 'TrickyQuestions',
  components: {
    vueSpinner: Spinner
  },
  data: () => ({
    answer: '',
    numTrickyQuestions: null,
    dialog: false,
    dialogNumber: null,
    answeredQuestions: [],
    answers: [],
    fetchingResults: true,
    timer: null,
  }),
  methods: {
    ...mapActions(["sendTrickyQuestionAnswer", "getAnsweredTrickyQuestions", "clearNotifications", "getTeam"]),
    openSubmitDialog(trickyQuestionNumber) {
      this.dialog = true
      this.dialogNumber = trickyQuestionNumber

      // Check if trickyQuestionNumber is in answeredQuestions
      if (this.answeredQuestions.includes(trickyQuestionNumber)) {
        this.answer = this.answers[trickyQuestionNumber]
      } else {
        this.answer = ''
      }
    },
    closeSubmitDialog() {
      this.dialog = false
    },
    async updateAnsweredTrickyQuestions() {
      const data = await this.getAnsweredTrickyQuestions()
      this.answeredQuestions = data.answeredQuestions
      this.answers = data.answers
    },
    async submitTrickyQuestionAnswer() {
      this.sendTrickyQuestionAnswer({
        trickyQuestionNumber: this.dialogNumber,
        answer: this.answer
      }).then(async () => {
        await this.updateAnsweredTrickyQuestions()
        this.dialog = false
        // Notification
        notify(this)
      })
    },
    shuffle(array) {
      // Used for gamla rallyt
      let currentIndex = array.length,  randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    },
    async getUserTeam() {
      const team = await this.getTeam()
      this.team = team
      if(team.t_gamla_rallyt){
        this.numTrickyQuestions = this.shuffle(Array.from({length: parseInt(process.env.VUE_APP_TRICKY_QUESTIONS)}, (_, i) => i + 1))
      } else {
        this.numTrickyQuestions = Array.from({length: parseInt(process.env.VUE_APP_TRICKY_QUESTIONS)}, (_, i) => i + 1)
      }
    },
  },
  mounted() {
    this.getUserTeam()
    this.updateAnsweredTrickyQuestions().then(() => {
      this.fetchingResults = false
    })

    // Poll for changes every 5s
    this.timer = setInterval(() => {
      this.updateAnsweredTrickyQuestions()
    }, 5000)
  },
  computed: mapGetters(["notification", "trickyLoading"]),
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style>

</style>